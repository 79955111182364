import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Query
 */

export const GET_MANY_STOCK_INVENTORY = gql(`
  query GetManyStockInventory(
    $filter: StockInventoryFilterInput
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getManyStockInventory(
      filter: $filter
      sort: $sort
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
      ...stockInventory
    }
  }
`)

export const GET_MANY_STOCK_INVENTORY_WITH_META = gql(`
  query GetManyStockInventoryWithMeta(
    $filter: StockInventoryFilterInput
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getManyStockInventoryWithMeta(
      filter: $filter
      sort: $sort
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
        data {
          ...stockInventory
        }
        meta {
          all 
        }
    }
  }
`)

export const GET_MANY_STOCK_INVENTORY_WITH_META_FOR_BOOTH = gql(`
  query GetManyStockInventoryWithMetaForBooth(
    $filter: StockInventoryFilterInput
    $productFilter: StockInventoryProductFilterInput
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getManyStockInventoryWithMetaForBooth(
      filter: $filter
      productFilter: $productFilter
      sort: $sort
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
        data {
          ...stockInventory
          product {
            _id 
            name
            sku
            productAlias
            images {
              src
            }
            status
            type
            productAttributeValues {
              _id 
              value
              productAttribute {
                _id
                key
                name
              }
            }
            ratio {
              productSku 
              productName 
              type
              amount
            }
            warehouseUnits {
              unit
              conversionFactor
              conversionUnit
              warehouse {
                _id
              }
            }
            unit 
            conversionUnit
          }
          reservedOrders {
            ...reservedOrdersForStockInventory
          }
          convertibleQuantity
        }
        meta {
          all 
        }
    }
  }
`)

export const GET_MANY_STOCK_INVENTORY_FOR_BOOTH_META = gql(`
  query GetManyStockInventoryForBoothMeta(
    $filter: StockInventoryFilterInput
    $productFilter: StockInventoryProductFilterInput
  ) {
    getManyStockInventoryForBoothMeta(
      filter: $filter
      productFilter: $productFilter
    ) {
      all
      convertible
      sub
    }
  }
`)

export const GET_STOCK_INVENTORY_DETAIL = gql(`
  query GetStockInventoryDetail($sku: String!, $warehouses: [String!]) {
    getStockInventoryDetail(sku: $sku, warehouses: $warehouses) {
      ...stockInventoryDetail
    }
  }
`)

export const GET_STOCK_INVENTORY = gql(`
  query GetStockInventory($filter: StockInventoryFilterInput!, $isRawMaterial: Boolean) {
    getStockInventory(filter: $filter, isRawMaterial: $isRawMaterial) {
      ...stockInventory
    }
  }
`)

export const COUNT_STOCK_INVENTORY = gql(`
  query CountStockInventory($filter: StockInventoryFilterInput) {
    countStockInventory(filter: $filter)
  }
`)

/**
 * Mutations
 */
export const ADJUST_WAREHOUSE_INVENTORY = gql(`
  mutation AdjustWarehouseInventory($data: [AdjustWarehouseInventoryInput!]!) {
    adjustWarehouseInventory(data: $data) {
      ...stockInventory
    }
  }
`)

export const UPDATE_STOCK_INVENTORY = gql(`
  mutation UpdateStockInventory($input: UpdateStockInventory!, $id: String!) {
    updateStockInventory(input: $input, id: $id) {
      ...stockInventory
    }
  }
`)

export const COPY_STOCK_INVENTORY = gql(`
  mutation CopyStockInventory($filter: StockInventoryFilterInput, $columns: [String!]!, $date: Date!) {
    copyStockInventory(filter: $filter, columns: $columns, date: $date)
  }
`)

export const REVALIDATE_STOCK_INVENTORY = gql(`
  mutation RevalidateStockInventory($productSku: String!, $warehouses: [String!]!) {
    revalidateStockInventory(productSku: $productSku, warehouses: $warehouses)
  }
`)

export const REVALIDATE_WAREHOUSE_STOCK_INVENTORY = gql(`
  mutation RevalidateWarehouseStockInventory($warehouse: String!) {
    revalidateWarehouseStockInventory(warehouse: $warehouse)
  }
`)

export const REMOVE_STOCK_MOVEMENT_AND_REVALIDATE_INVENTORY = gql(`
  mutation RemoveStockMovementAndRevalidateInventory($docIds: [String!]!) {
    removeStockMovementAndRevalidateInventory(docIds: $docIds)
  }
`)

export const REVALIDATE_REMAINING_QUANTITY_IN_WAREHOUSE = gql(`
  mutation RevalidateRemainingQuantityInWarehouse($warehouse: String!) {
    revalidateRemainingQuantityInWarehouse(warehouse: $warehouse)
  }
`)

export const SYNC_CONVERTIBLE_PRODUCTS_STOCK_INVENTORY = gql(`
  mutation SyncConvertibleProductsStockInventory($warehouseId: String!) {
    syncConvertibleProductsStockInventory(warehouseId: $warehouseId)
  }
`)

export const REVALIDATE_STOCK_BY_DOCUMENT_ID = gql(`
  mutation RevalidateStockByDocumentID($docId: String!) {
    revalidateStockByDocumentID(docId: $docId)
  }
`)
