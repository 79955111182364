import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Query
 */

export const GET_PRODUCTS = gql(`
  query GetProducts($paginationOffset: Float, $paginationLimit: Float, $filter: ProductFilterInput, $sort: SortInput) {
    products(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, filter: $filter, sort: $sort) {
      ...product
    }

    productsMeta(filter: $filter) {
      ...productsMeta
    }
  }
`)

/**
 * @deprecated use GET_LEAN_PRODUCTS_OPTIMIZED instead
 * */
export const GET_LEAN_PRODUCTS = gql(`
  query GetLeanProducts(
    $paginationOffset: Float
    $paginationLimit: Float
    $filter: ProductFilterInput
    $sort: SortInput
  ) {
    leanProducts(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, filter: $filter, sort: $sort) {
      ...leanProduct
    }
  }
`)

export const GET_LEAN_PRODUCTS_OPTIMIZED = gql(`
  query GetLeanProductsOptimized(
    $paginationOffset: Float
    $paginationLimit: Float
    $filter: ProductFilterInput
    $sort: SortInput
    $projection: ProductProjectionInput
    $search: String
  ) {
    leanProductsOptimized(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      filter: $filter
      sort: $sort
      projection: $projection
      search: $search
    ) {
      ...leanProduct
    }
  }
`)

export const GET_PRODUCT_NAMES = gql(`
  query GetProductNames(
    $paginationOffset: Float
    $paginationLimit: Float
    $filter: ProductFilterInput
    $sort: SortInput
  ) {
    products(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, filter: $filter, sort: $sort) {
      _id
      name
      sku
      productAlias
    }
  }
`)

export const GET_LEAN_PRODUCTS_FOR_TDS_BULK_CREATE = gql(`
  query GetLeanProductsForTDSBulkCreate(
    $paginationOffset: Float
    $paginationLimit: Float
    $filter: ProductFilterInput
    $sort: SortInput
  ) {
    leanProducts(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, filter: $filter, sort: $sort) {
      ...leanProductForTDSBulkCreate
    }
  }
`)

/**
 * @deprecated use GET_LEAN_PRODUCTS_WITH_PRODUCT_ATTRIBUTES_OPTIMIZED instead
 */
export const GET_LEAN_PRODUCTS_WITH_PRODUCT_ATTRIBUTES = gql(`
  query GetLeanProductsWithProductAttributes(
    $paginationOffset: Float
    $paginationLimit: Float
    $filter: ProductFilterInput
    $sort: SortInput
  ) {
    leanProductsWithProductAttributes(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, filter: $filter, sort: $sort) {
      ...leanProductWithProductAttributes
    }
  }
`)

export const GET_LEAN_PRODUCTS_WITH_PRODUCT_ATTRIBUTES_OPTIMIZED = gql(`
  query GetLeanProductsWithProductAttributesOptimized(
    $paginationOffset: Float
    $paginationLimit: Float
    $filter: ProductFilterInput
    $sort: SortInput
    $search: String
    $availableStock: Boolean
    $warehouseId: String
  ) {
    leanProductsWithProductAttributesOptimized(filter: $filter, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset, sort: $sort, search: $search, availableStock: $availableStock, warehouseId: $warehouseId) {
      ...leanProductWithProductAttributes
    }
  }
`)

export const GET_LEAN_PRODUCTS_WITH_WAREHOUSE_STOCK = gql(`
  query GetLeanProductsWithWarehouseStock(
    $paginationOffset: Float
    $paginationLimit: Float
    $filter: ProductFilterInput
    $sort: SortInput
    $warehouseId: String
    $availableStock: Boolean
  ) {
    leanProductsWithWarehouseStock(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, filter: $filter, sort: $sort, warehouseId: $warehouseId, availableStock: $availableStock) {
      ...leanProductWithProductAttributes
      warehouseStock
    }
  }
`)

export const GET_LEAN_PRODUCTS_WITH_META = gql(`
  query GetLeanProductsWithMeta(
    $paginationOffset: Float
    $paginationLimit: Float
    $filter: ProductFilterInput
    $sort: SortInput
  ) {
    leanProducts(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, filter: $filter, sort: $sort) {
      ...leanProduct
    }

    productsMeta(filter: $filter) {
      ...productsMeta
    }
  }
`)

export const GET_LEAN_CHILD_PRODUCTS = gql(`
  query GetLeanChildProducts($input: ProductSkusInput!) {
    leanChildProducts(input: $input) {
      parentProducts {
        ...leanProduct
      }
      childProducts {
        ...leanProduct
      }
    }
  }
`)

export const GET_RAW_MATERIAL_PRODUCT = gql(`
  query GetRawMaterialProduct($params: FindOneProductInput!) {
    rawMaterialProduct(params: $params) {
      ...product
    }
  }
`)

/**
 * @deprecated use GET_PRODUCTS_META_OPTIMIZED instead
 */
export const GET_PRODUCTS_META = gql(`
  query GetProductsMeta(
      $filter: ProductFilterInput
      $warehouseId: String
      $availableStock: Boolean
    ) {
      productsMeta(filter: $filter, warehouseId: $warehouseId, availableStock: $availableStock) {
        ...productsMeta
      }
    }
`)

export const GET_PRODUCTS_META_OPTIMIZED = gql(`
  query GetProductsMetaOptimized($filter: ProductFilterInput, $search: String, $warehouseId: String, $availableStock: Boolean) {
    productsMetaOptimized(filter: $filter, search: $search, warehouseId: $warehouseId, availableStock: $availableStock) {
      ...productsMeta
    }
  }
`)

export const GET_PRODUCTS_FAKE_STOCK_META = gql(`
  query GetProductFakeStocksMeta($filter: ProductFilterInput) {
    productsFakeStockMeta(filter: $filter) {
      ...productsFakestockMeta
    }
  }
`)

export const GET_PRODUCT = gql(`
  query GetProduct($params: FindOneProductInput!) {
    product(params: $params) {
      ...product
    }
  }
`)

export const GET_PRODUCTS_WITH_LAYER = gql(`
  query ProductsWithLayer($params: ProductsRawestChildInput!) {
    productsWithLayer(params: $params) {
      sku
      name
      images {
        src
      }
      ratio {
        productSku
        productName
      }
      layers {
        productSku
        productName
        productImages {
          src
        }
        layer
        parentSku
        productSku
        amount
      }
    }
  }
`)

export const GET_PRODUCTS_WITH_SINGLE_LAYER = gql(`
  query ProductsWithSingleLayer($params: ProductsRawestChildInput!) {
    productsWithSingleLayer(params: $params) {
      sku
      name
      images {
        src
      }
      ratio {
        productSku
        productName
      }
      layers {
        product
        productSku
        productName
        productImages {
          src
        }
        layer
        parentSku
        productSku
        amount
      }
    }
  }
`)

export const GET_MANY_PRODUCT_RECOMMENDATIONS = gql(`
  query GetManyProductRecommendations($sort: SortInput) {
    getManyProductRecommendations(sort: $sort) {
      _id
      name
      createdAt
      updatedAt
    }
  }
`)

export const GET_PRODUCTS_FOR_PRODUCT_RECOMMENDATION = gql(`
  query GetManyProductsForProductRecommendations($paginationOffset: Float, $paginationLimit: Float, $filter: ProductFilterInput, $sort: SortInput) {
    getManyProductsForProductRecommendations(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, filter: $filter, sort: $sort) {
      _id 
      name 
      sku 
      productAlias 
      images {
        src
      }
      productAttributeValues {
        _id 
        value 
        productAttribute { 
          _id 
          key
          name
        }
      }
      category {
        _id
        name
        productAttributes {
          _id 
          key
          name
          values {
            _id 
            value
          }
        }
      }
    }
  }
`)

export const GET_MANY_RECOMMENDED_PRODUCTS = gql(`
  query GetManyRecommendedProducts(
    $paginationOffset: Float, $paginationLimit: Float, $filter: RecommendedProductFilterInput, $sort: SortInput
  ) {
    getManyRecommendedProducts(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, filter: $filter, sort: $sort) {
      _id 
      priority
      product {
        _id 
        name 
        sku 
        productAlias 
        images {
          src
        }
        productAttributeValues {
          _id 
          value 
          productAttribute { 
            _id 
            key
            name
          }
        }
        category {
          _id
          name
          productAttributes {
            _id 
            key
            name
            values {
              _id
              value
            }
          }
        }
      }
      productRecommendation {
        _id
        name
      }
    }
  }
`)

export const GET_RECOMMENDED_PRODUCTS_BY_ATTRIBUTE_VALUES = gql(`
  query GetRecommendedProductsByAttributeValues(
    $paginationOffset: Float, $paginationLimit: Float, $productAttributeValues: [String!]!, $sort: SortInput
  ) {
    getRecommendedProductsByAttributeValues(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, productAttributeValues: $productAttributeValues, sort: $sort) {
      _id 
      name 
      sku 
      productAlias 
      images {
        src
      }
      productAttributeValues {
        _id 
        value 
        productAttribute { 
          _id 
          key
          name
        }
      }
      category {
        _id
        name
        productAttributes {
          _id 
          key
          name
          values {
            _id
            value
          }
        }
      }
    }
  }
`)
/**
 * Mutation
 */

export const CREATE_PRODUCT = gql(`
  mutation CreateProduct($payload: ProductInput!) {
    createProduct(payload: $payload) {
      _id
      sku
      name
    }
  }
`)

export const UPDATE_PRODUCT = gql(`
  mutation UpdateProduct($productInput: ProductInput!, $findOneProductInput: FindOneProductInput!) {
    updateProduct(payload: $productInput, params: $findOneProductInput) {
      _id
    }
  }
`)

export const CONFIGURE_IS_ACTIVE_REPORT_PRODUCT = gql(`
  mutation ConfigureIsActiveReportProduct($isActiveReport: Boolean!, $params: FindOneProductInput!) {
    configureIsActiveReportProduct(isActiveReport: $isActiveReport, params: $params) {
      _id
    }
  }
`)
export const UPDATE_PLAIN_PRODUCT = gql(`
  mutation UpdatePlainProduct($payload: ProductPlainUpdateInput!, $params: FindOneProductInput!) {
    updatePlainProduct(payload: $payload, params: $params) {
      _id
    }
  }
`)
export const UPDATE_PLAIN_PRODUCTS = gql(`
  mutation UpdatePlainProducts($payload: ProductPlainManyUpdateInput!, $params: ProductFilterInput!) {
    updatePlainProducts(payload: $payload, params: $params) {
      _id
    }
  }
`)

export const DELETE_PRODUCTS = gql(`
  mutation DeleteProducts($params: DeleteProductsInput!) {
    deleteProducts(params: $params) {
      deletedCount
    }
  }
`)

export const SYNC_ALL_STOCK = gql(`
  mutation SyncAllStock($sources: [String!]!) {
    syncAllStock(sources: $sources)
  }
`)

export const SYNC_STOCK = gql(`
  mutation SyncStock($productIds: [String!]!, $sources: [String!]!) {
    syncStock(productIds: $productIds, sources: $sources)
  }
`)

export const ADJUST_ACTUAL_STOCK = gql(`
  mutation AdjustActualStock($input: AdjustActualStockInput!) {
    adjustActualStock(input: $input) {
      ...leanProduct
    }
  }
`)

export const UPSERT_FAKE_STOCK = gql(`
  mutation UpsertFakeStock($input: UpsertFakeStockInput!) {
    upsertFakeStock(input: $input) {
      ...leanProduct
    }
  }
`)

export const UPDATE_MANY_PRODUCT_SIZE = gql(`
  mutation UpdateManyProductSize($input: [UpdateProductSizeInput!]!) {
    updateManyProductSize(input: $input) {
      ...leanProduct
    }
  }
`)

export const UPSERT_PRODUCT_PRICING_INFO = gql(`
  mutation UpsertProductPricingInfo($input: PricingInfoInput!, $sku: String!) {
    upsertProductPricingInfo(input: $input, sku: $sku) {
      ...leanProduct
    }
  }
`)

export const REMOVE_PRODUCT_PRICING_INFO = gql(`
  mutation RemoveProductPricingInfo($minAmount: Float!, $sku: String!, $source: String, $sourceRef: String) {
    removeProductPricingInfo(minAmount: $minAmount, sku: $sku, source: $source, sourceRef: $sourceRef)
  }
`)

export const CREATE_PRODUCT_RECOMMENDATION = gql(`
  mutation CreateProductRecommendation($name: String!) {
    createProductRecommendation(name: $name) {
      _id
      name
    }
  }
`)

export const UPDATE_PRODUCT_RECOMMENDATION = gql(`
  mutation UpdateProductRecommendation($id: String!, $name: String!) {
    updateProductRecommendation(id: $id, name: $name) {
      _id
      name
    }
  }
`)

export const UPSERT_MANY_RECOMMENDED_PRODUCTS = gql(`
  mutation UpsertManyRecommendedProducts($payload: [CreateRecommendedProductPayload!]!,$productRecommendation: String!) {
    upsertManyRecommendedProducts(payload: $payload, productRecommendation: $productRecommendation) {
      _id 
    }
  }
`)

export const UPDATE_MANY_PRODUCTS_RECOMMENDED = gql(`
  mutation UpdateManyProductsRecommended($skus: [String!]!, $isRecommended: Boolean!) {
    updateManyProductsRecommended(skus: $skus, isRecommended: $isRecommended)
  }
`)

export const SYNC_MANY_WAREHOUSE_PRODUCTS_UNITS = gql(`
  mutation SyncManyWarehouseProductsUnits($warehouseIds: [String!]!) {
    syncManyWarehouseProductsUnits(warehouseIds: $warehouseIds)
  }
`)

export const UPDATE_MANY_PRODUCT_TDS_TYPE = gql(`
  mutation UpdateManyProductTDSType($input: [UpdateProductTDSTypeInput!]!) {
    updateManyProductTDSType(input: $input)
  }
`)

export const UPDATE_MANY_PRODUCT_RM_TYPE = gql(`
  mutation UpdateManyProductRMType($input: [UpdateProductRMTypeInput!]!) {
    updateManyProductRMType(input: $input)
  }
`)
