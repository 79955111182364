import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Query
 */

export const GET_RAW_MATERIAL_TYPE = gql(`
  query GetRawMaterialType($id: String!) {
    getRawMaterialType(id: $id) {
      ...rawMaterialType
    }
  }
`)

export const GET_MANY_RAW_MATERIAL_TYPE = gql(`
  query GetManyRawMaterialType(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: RawMaterialTypeFilterInput
  ) {
    getManyRawMaterialType(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...rawMaterialType
    }
  }
`)

export const GET_MANY_RAW_MATERIAL_TYPE_WITH_META = gql(`
  query GetManyRawMaterialTypeWithMeta(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: RawMaterialTypeFilterInput
  ) {
    getManyRawMaterialTypeWithMeta(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      data {
        ...rawMaterialType
      }
      meta {
        all
      }
    }
  }
`)

/**
 * Mutation
 */

export const CREATE_RAW_MATERIAL_TYPE = gql(`
  mutation CreateRawMaterialType($input: RawMaterialTypeInput!) {
    createRawMaterialType(input: $input) {
      ...rawMaterialType
    }
  }
`)

export const UPDATE_RAW_MATERIAL_TYPE = gql(`
  mutation UpdateRawMaterialType($input: RawMaterialTypeUpdateInput!) {
    updateRawMaterialType(input: $input) {
      ...rawMaterialType
    }
  }
`)

export const DELETE_RAW_MATERIAL_TYPE = gql(`
  mutation DeleteRawMaterialType($key: String!) {
    deleteRawMaterialType(key: $key) {
      key
    }
  }
`)
