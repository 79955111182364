import { gql } from 'graphql-generator/__generated__/gql'
//import gql from 'graphql-tag';
/**
 * Inventory Adjustment Order
 */

export const GET_INVENTORY_ADJUSTMENT_ORDER = gql(`
  query GetInventoryAdjustmentOrder($orderId: String!) {
    inventoryAdjustmentOrder(orderId: $orderId) {
      ...inventoryAdjustmentOrder
    }
  }
`)

export const GET_INVENTORY_ADJUSTMENT_ORDERS = gql(`
  query GetInventoryAdjustmentOrders(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $search: String
    $filter: InventoryAdjustmentOrderFilterInput
    $withProductCategory: Boolean
  ) {
    inventoryAdjustmentOrders(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      search: $search
      filter: $filter
      withProductCategory: $withProductCategory
    ) {
      ...leanInventoryAdjustmentOrder
    }
  }
`)

export const GET_TFG_INVENTORY_ADJUSTMENT_ORDERS = gql(`
  query GetTFGInventoryAdjustmentOrders(
    $paginationOffset: Float
    $paginationLimit: Float
    $search: String
    $sort: SortInput
    $filter: InventoryAdjustmentOrderFilterInput
    $withProductCategory: Boolean
  ) {
    inventoryAdjustmentOrders(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      search: $search
      sort: $sort
      filter: $filter
      withProductCategory: $withProductCategory
    ) {
      ...leanTFGInventoryAdjustmentOrder
    }
  }
`)

export const GET_ONE_INVENTORY_ADJUSTMENT_ORDER = gql(`
  query GetOneInventoryAdjustmentOrder($filter: InvAdjustmentOrderAdvanceFilterInput!) {
    getOneInventoryAdjustmentOrder(filter: $filter) {
      ...inventoryAdjustmentOrder
    }
  }
`)

export const GET_MANY_PURE_INVENTORY_ADJUSTMENT_ORDERS = gql(`
  query GetManyPureInventoryAdjustmentOrders(
    $filter: InvAdjustmentOrderAdvanceFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getManyPureInventoryAdjustmentOrders(
      filter: $filter
      sort: $sort
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
      ...pureInventoryAdjustmentOrder
    }
  }
`)

export const GET_INVENTORY_ADJUSTMENT_ORDERS_WITH_STOCK_MOVEMENTS = gql(`
  query GetInventoryAdjustmentOrdersWithStockMovements(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: InventoryAdjustmentOrderFilterInput
  ) {
    invAdjustmentOrdersWithStockMoments(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...leanInventoryAdjustmentOrder
      stockMovements {
        _id
        product {
          _id
          sku
          name
        }
        productSku
        docId
        type
        unit
        conversionUnit
        conversionFactor
        actualAmountInUnit
        actualAmountInBaseUnit
      }
    }
  }
`)

export const GET_INVENTORY_ADJUSTMENT_ORDERS_WITH_ADVANCED_SEARCH = gql(`
  query GetInventoryAdjustmentOrdersWithAdvancedSearch(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: InvAdjustmentOrderAdvanceFilterInput
    $search: InvAdjustmentOrderAdvanceSearchInput
  ) {
    inventoryAdjustmentOrdersWithAdvancedSearch(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
      search: $search
    ) {
      ...leanInventoryAdjustmentOrder
    }
  }
`)

export const GET_INVENTORY_ADJUSTMENT_ORDERS_RELATED_USERS = gql(`
  query GetInventoryAdjustmentOrdersRelatedUsers(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: InventoryAdjustmentOrderFilterInput
  ) {
    inventoryAdjustmentOrders(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...inventoryAdjustmentOrderRelatedUser
    }
  }
`)

export const GET_INVENTORY_ADJUSTMENT_ORDERS_META = gql(`
  query GetInventoryAdjustmentOrdersMeta($search: String, $filter: InventoryAdjustmentOrderFilterInput) {
    inventoryAdjustmentOrdersMeta(search: $search, filter: $filter) {
      all
      waitApproval
      approved
      confirmed
      open
      checked
      inProgress
      done
      cancelled
      preparation
      shipping
      history
      waitPickupConfirm
      gotInCar
      waitInspection
      packed
      prepared
      waitConfirm
      production
      waitToCheck
    }
  }
`)

export const CREATE_INVENTORY_ADJUSTMENT_ORDER = gql(`
  mutation CreateInventoryAdjustmentOrder($input: InventoryAdjustmentOrderInput!) {
    createInventoryAdjustmentOrder(input: $input) {
      _id
      orderId
    }
  }
`)
export const CREATE_INVENTORY_ADJUSTMENT_ORDER_WITHOUT_TRACKING = gql(`
  mutation CreateInventoryAdjustmentOrderWithoutTracking($input: InventoryAdjustmentOrderInput!) {
    createInventoryAdjustmentOrderWithoutTracking(input: $input) {
      _id
      orderId
    }
  }
`)

export const CREATE_INV_DEPOSIT_DOC = gql(`
  mutation CreateInvDepositDoc($input: InvDepositInput!) {
    createInvDepositDoc(input: $input) {
      orderId
    }
  }
`)

export const UPDATE_INV_DEPOSIT_DOC = gql(`
  mutation UpdateInvDepositDoc($id: String!, $input: UpdateInvDepositInput!) {
    updateInvDepositDoc(id: $id, input: $input) {
      ...inventoryAdjustmentOrder
    }
  }
`)

export const GENERATE_INVENTORY_QR = gql(`
  mutation GenerateInventoryQR($orderId: String!) {
    generateInventoryQR(orderId: $orderId)
  }
`)

export const UPDATE_DETAILS_INVENTORY_ADJUSTMENT_ORDER = gql(`
  mutation UpdateDetailsInventoryAdjustmentOrder($id: String!, $input: UpdateDetailsInventoryAdjustmentOrderInput!) {
    updateDetailsInventoryAdjustmentOrder(id: $id, input: $input) {
      ...inventoryAdjustmentOrder
    }
  }
`)

export const UPDATE_INVENTORY_ADJUSTMENT_ORDER = gql(`
  mutation UpdateInventoryAdjustmentOrder($id: String!, $input: UpdateInventoryAdjustmentOrderInput!, $staffId: String) {
    updateInventoryAdjustmentOrder(id: $id, input: $input, staffId: $staffId) {
      ...inventoryAdjustmentOrder
    }
  }
`)

export const RECEIVED_WITHDRAW_RAW_MATERIAL_PRODUCTS = gql(`
  mutation ReceivedWithdrawRawMaterialProducts($id: String!, $input: ReceivedWithdrawRawMaterialProductsInput!) {
    receivedWithdrawRawMaterialProducts(id: $id, input: $input)
  }
`)

export const UPDATE_INVENTORY_ADJUSTMENT_ORDER_STATUS = gql(`
  mutation UpdateInventoryAdjustmentOrderStatus($id: String!, $input: UpdateInventoryAdjustmentOrderStatusInput!,  $staffId: String) {
    updateInventoryAdjustmentOrderStatus(id: $id, input: $input, staffId: $staffId) {
      ...inventoryAdjustmentOrder
    }
  }
`)

export const UPDATE_MANY_INVENTORY_ADJUSTMENT_ORDER_STATUS = gql(`
  mutation UpdateManyInventoryAdjustmentOrderStatus($input: UpdateManyInventoryAdjustmentOrderStatusInput!) {
    updateManyInventoryAdjustmentOrderStatus(input: $input) {
      ...inventoryAdjustmentOrder
    }
  }
`)

export const CREATE_ORDER_FROM_REF_ORDER = gql(`
  mutation CreateOrderFromRefOrder($input: CreateOrderFromRefOrderInput!) {
    createOrderFromRefOrder(input: $input) {
      ...inventoryAdjustmentOrder
    }
  }
`)
export const CREATE_ORDER_FROM_REF_ORDER_WITH_PRODUCT_TRACKINGS = gql(`
  mutation CreateOrderFromRefOrderWithProductTrackings($input: CreateOrderFromRefOrderWithProductTrackingsInput!) {
    createOrderFromRefOrderWithProductTrackings(input: $input) {
      ...inventoryAdjustmentOrder
    }
  }
`)

export const CREATE_MANUFACTURING_ORDER = gql(`
  mutation CreateManufacturingOrder($input: ManufacturingOrderInput!) {
    createManufacturingOrder(input: $input) {
      _id
      orderId
    }
  }
`)

export const APPROVE_QC_PASSED_AND_CREATE_TRANSFER_ORDER = gql(`
  mutation ApproveQCPassedAndCreateTransferOrder(
    $orderId: String!
    $inputs: [ApproveQCPassedAndTFOrderInput!]!
    $note: String
  ) {
    approveQCPassedAndCreateTransferOrder(orderId: $orderId, inputs: $inputs, note: $note) {
      _id
      orderId
    }
  }
`)

export const DEFECTIVE_PRODUCT_AND_UPDATE_ORDER = gql(`
  mutation DefectiveProductAndUpdateOrder(
    $staff: String!
    $orderId: String!
    $inputs: [ApproveQCPassedAndTFOrderInput!]!
    $note: String
  ) {
    defectiveProductAndUpdateOrder(staff: $staff, orderId: $orderId, inputs: $inputs, note: $note) {
      _id
      orderId
    }
  }
`)

export const DELETE_STAFF_JOBS = gql(`
  mutation DeleteStaffJobs($input: DeleteStaffJobInput!) {
    deleteStaffJobs(input: $input) {
      _id
      orderId
    }
  }
`)

export const SPLIT_TFG_DOCUMENT = gql(`
  mutation SplitTFGDocument($orderId: String!, $items: [SplitTFGItemInput!]!) {
    splitTFGDocument(orderId: $orderId, items: $items) {
      _id
      orderId
    }
  }
`)

/**
 * Production Order
 */

export const GET_PRODUCTION_ORDERS = gql(`
  query GetProductionOrders($paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
    productionOrders(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort) {
      ...productionOrder
    }

    productionOrdersMeta {
      total
    }
  }
`)

export const CREATE_PRODUCTION_ORDER = gql(`
  mutation CreateProductionOrder($input: ProductionOrderInput!) {
    createProductionOrder(input: $input) {
      ...productionOrder
    }
  }
`)

export const GET_PRODUCTION_ORDER = gql(`
  query GetProductionOrder($id: String!) {
    productionOrder(id: $id) {
      ...productionOrder
    }
  }
`)

export const GET_PRODUCTION_ORDER_DAILY_REPORT = gql(`
  query GetProductionOrderDailyReport(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: ProductionOrderDailyReportInput
  ) {
    productionOrderDailyReport(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      _id
      orderId
      status
      action
      activityTimeMeta {
        close_production_job
        defect_product
      }
      activityMeta{
        assign_production_job
        wait_for_qc_job
        close_production_job
        defect_product
        assign_from_production_job
        import_to_inventory_job
      }
      createdAt
      updatedAt
    }
  }
`)
export const GET_PRODUCTION_ORDER_DAILY_REPORT_META = gql(`
  query GetProductionOrderDailyReportMeta(
    $filter: ProductionOrderDailyReportInput
  ) {
    productionOrderDailyReportMeta(
      filter: $filter
    ) {
      total
    }
  }
`)

export const COPY_PRODUCTION_ORDER_DAILY_REPORT = gql(`
  mutation CopyProductionOrderDailyReport($filter: ProductionOrderDailyReportInput) {
    copyProductionOrderDailyReport(filter: $filter)
  }
`)

export const GET_INVENTORY_ADJUSTMENT_ORDER_BY_PRODUCT_TRACKING = gql(`
  query GetInvAdjustmentOrderByProductTracking($input: InvAdjustmentOrderByProductTrackingInput!) {
    getInvAdjustmentOrderByProductTracking(input: $input) {
      _id
      orderId
      adjustmentDate
      status
      typeQRCode
      action
      productTrackings {
        ...productTracking
      }
      documentOverview {
        ...documentOverview
      }
    }
  }
`)

/**
 * Transfer Order
 */

export const GET_TRANSFER_ORDER = gql(`
  query GetTransferOrder($id: String!) {
    transferOrder(id: $id) {
      ...inventoryTransferOrder
    }
  }
`)

export const GET_TRANSFER_ORDERS = gql(`
  query GetTransferOrders($paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
    transferOrders(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort) {
      ...inventoryTransferOrder
    }

    transferOrdersMeta {
      total
    }
  }
`)

export const CREATE_TRANSFER_ORDER = gql(`
  mutation CreateTransferOrder($input: TransferOrderInput!) {
    createTransferOrder(input: $input) {
      ...inventoryTransferOrder
    }
  }
`)

/**
 * Product Issue Report
 */

export const CREATE_PRODUCT_ISSUE_REPORT = gql(`
  mutation CreateProductIssueReport($input: ProductIssueReportInput!) {
    createProductIssueReport(input: $input) {
      ...productIssueReport
    }
  }
`)

export const GET_PRODUCT_ISSUE_REPORTS = gql(`
  query GetProductIssueReports($paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
    productIssueReports(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort) {
      ...productIssueReport
    }

    productIssueReportsMeta {
      total
    }
  }
`)

export const GET_PRODUCT_ISSUE_REPORT = gql(`
  query GetProductIssueReport($orderId: String!) {
    productIssueReport(orderId: $orderId) {
      ...productIssueReport
    }
  }
`)

export const DOCUMENT_QRCODE_TRACKING_REPORTS = gql(`
  query DocumentQRCodeTrackingReports($date: String!, $warehouse: String!) {
    documentQRCodeTrackingReports(date: $date, warehouse: $warehouse) {
      uniqueId
      productTrackingStatus
      documentIds
      isProductionOrder

      productId
      productSku
      productName
      productImage
      category {
        name
        _id
      }
      attributes

      orderStatus
      packageStatus
      packages {
        packageId
        trackingNumber
        referenceNumber
        status
      }
      deliveryDate
      deliveryChannel {
        _id
        name
      }
      source {
        name
        sourceType
      }
      sourceCreatedTime
      prodLine

      preparedCoverTime
      preparedFrameTime
      preparedPlywoodTime
      preparedSpongeTime

      todo
      inProgress
      done
      cancelled
    }
  }
`)

export const GET_INVENTORY_ADJUSTMENT_ORDER_JUST_PRODUCTS = gql(`
  query GetInventoryAdjustmentOrderJustProducts($orderId: String!) {
    inventoryAdjustmentOrder(orderId: $orderId) {
      _id
      orderId
      adjustmentDate
      action
      products {
        product {
          _id
          sku
          name
          productAlias
          type
          categoryId
          images {
            imageType
            src
            order
          }

          status
          createdAt
          updatedAt

          onePerPackage
        }
        quantity
      }
    }
  }
`)

export const SCAN_TO_RECEIVE_RAW_MATERIAL_INV_ADJUSTMENT_ORDER = gql(`
  mutation ScanToReceiveRawMaterialInvAdjustmentOrder($orderId: String!, $sku: String!) {
    scanToReceiveRawMaterialInvAdjustmentOrder(orderId: $orderId, sku: $sku) {
      ...inventoryAdjustmentOrder
    }
  }
`)

export const COPY_INVENTORY_ORDERS = gql(`
  mutation CopyInventoryOrders($filter: InventoryAdjustmentOrderFilterInput!, $action: String!, $isSummary: Boolean) {
    copyInventoryOrders(filter: $filter, action: $action, isSummary: $isSummary)
  }
`)

export const GET_INVENTORY_ADJUSTMENT_ORDER_BY_PRODUCT_TRACKING_UNIQUE_ID = gql(`
  query GetInventoryAdjustmentOrderByProductTrackingUniqueId($params: InvAdjustmentOrderByProductTrackingUniqueIdInput!) {
    inventoryAdjustmentOrderByProductTrackingUniqueId(params: $params) {
      ...inventoryAdjustmentOrder
    }
  }
`)

export const GET_PRODUCTION_ORDER_LOG_FOR_EXPORT = gql(`  
  query GetProductionOrderLogForExport($startDate: Date!, $endDate: Date!, $quotaType: String!) {
    getProductionOrderLogForExport(startDate: $startDate, endDate: $endDate, quotaType: $quotaType) {
      docId
      status
      sku
      docQuantity
      createdAt
      updatedAt
      warehouse
      createdByEmail
      createdByName
      approveStaffId
      approveStaffName
      quotaType
      duration
    }
  }
`)
