import { gql } from 'graphql-generator/__generated__/gql'

/** ------------------------------------ PUBLIC QUERY(S) ------------------------------------ */
export const GET_ONE_PUBLIC_DELIVERY_FLEET = gql(`
  query GetOnePublicDeliveryFleet($filters: FleetFilterInput, $sort: SortInput) {
    getOnePublicDeliveryFleet(filters: $filters, sort: $sort) {
      driverInfo {
        driver {
          _id
          displayName
          phoneNumber
        }
        vehicle {
          _id
          license
        }
      }
    }
  }
`)

/** --------------------------------------- QUERY(S) ---------------------------------------- */
export const GET_FLEET = gql(`
  query GetFleet($filter: FleetFilterInput!, $sort: SortInput, $meta: Boolean) {
    getFleet(filter: $filter, sort: $sort, meta: $meta) {
      ...deliveryFleet
    }
  }
`)

export const GET_LEAN_FLEET = gql(`
  query GetLeanFleet($filter: FleetFilterInput!, $sort: SortInput, $meta: Boolean) {
    getLeanFleet(filter: $filter, sort: $sort, meta: $meta) {
      ...leanDeliveryFleet
    }
  }
`)

export const GET_MANY_FLEET = gql(`
  query GetManyFleet($filter: FleetFilterInput, $sort: SortInput, $paginationOffset: Float, $paginationLimit: Float, $meta: Boolean) {
    getManyFleet(
      filter: $filter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      meta: $meta
    ) {
      ...deliveryFleet
    }
  }
`)

export const GET_MANY_LEAN_FLEET = gql(`
  query GetManyLeanFleet(
    $filter: FleetFilterInput
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
    $meta: Boolean
  ) {
    getManyLeanFleet(
      filter: $filter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      meta: $meta
    ) {
      ...leanDeliveryFleet
    }
  }
`)

export const GET_FLEET_STATUS_META = gql(`
  query GetFleetStatusMeta($filters: FleetFilterInput!) {
    getFleetStatusMeta(filters: $filters) {
      all
      draft
      queued
      driver_ready
      package_ready
      shipping
      finished
      cancelled
    }
  }
`)

export const GET_DELIVERY_DATE_AND_ZONE = gql(`
  query GetDeliveryDateAndZone($from: Date!, $to: Date!) {
    getFleetDeliveryDateAndZone(from: $from, to: $to) {
      zones {
        zipcode
        district
      }
      totalQueued
      deliveryDate
      totalPackageSize
    }
  }
`)

export const GET_FLEET_ITEM_ZIPCODE_AND_DISTRICT = gql(`
  query GetFleetItemZipcodeAndDistrict($filter: FleetFilterInput!) {
    getFleetItemZipcodeAndDistrict(filter: $filter) {
      zipcode
      district
      sourceOrderIds
    }
  }
`)

export const GET_FLEETS_BY_ZIPCODES = gql(`
  query GetFleetsByZipcodes($zipcodes: [String!]!, $deliveryDate: DateTimeFilterInput) {
    getFleetsByZipcodes(zipcodes: $zipcodes, deliveryDate: $deliveryDate) {
      ...leanDeliveryFleet
    }
  }
`)

export const COUNT_FLEET = gql(`
  query CountFleet($filter: FleetFilterInput) {
    countFleet(filter: $filter)
  }
`)

export const GET_MANY_AVAILABLE_FLEET = gql(`
  query GetManyAvailableFleet(
    $filter: AvailableFleetFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
    $meta: Boolean
  ) {
    getManyAvailableFleet(
      filter: $filter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      meta: $meta
    ) {
      ...deliveryFleet
    }
  }
`)

export const GET_MANY_AVAILABLE_FLEET_COUNT = gql(`
  query GetManyAvailableFleetCount($filter: AvailableFleetFilterInput!) {
    getManyAvailableFleetCount(filter: $filter)
  }
`)

export const GET_OPTIMIZED_ROUTE = gql(`
  query GetOptimizedRoute($input: GetOptimizedRouteInput!) {
    getOptimizedRoute(input: $input) {
      orders {
        ...optimizedRouteOrder
      }
      distance
      duration
      multipleDirectionsUrl
      startPoint {
        name
        address
      }
      endPoint {
        name
        address
      }
      leadTimeMinute
      departureTime
      inputSourceOrderIds
    }
  }
`)

// export const GET_ROUTE_META = gql(`
//   query GetRouteMeta($input: GetRouteMetaInput!) {
//     getRouteMeta(input: $input) {
//       orders {
//         ...optimizedRouteOrder
//       }
//       distance
//       duration
//       multipleDirectionsUrl
//       startPoint {
//         name
//         address
//       }
//       endPoint {
//         name
//         address
//       }
//       leadTimeMinute
//       departureTime
//     }
//   }
// `)

export const GET_COMBINE_DELIVERY_FLEET_META = gql(`
  query GetCombineDeliveryFleetMeta($input: CombineDeliveryFleetInput!) {
    getCombineDeliveryFleetMeta(input: $input) {
      fleetId
      deliveryPoint
      unit
    }
  }
`)

/** -------------------------------------- MUTATION(S) -------------------------------------- */
export const CREATE_FLEET = gql(`
  mutation CreateFleet($data: FleetInput!) {
    createFleet(data: $data) {
      ...leanDeliveryFleet
    }
  }
`)

export const CREATE_EMPTY_FLEET = gql(`
  mutation CreateEmptyFleet($data: EmptyFleetInput!) {
    createEmptyFleet(data: $data) {
      ...leanDeliveryFleet
    }
  }
`)

export const UPDATE_FLEET = gql(`
  mutation UpdateFleet($data: UpdateFleetInput!) {
    updateFleet(data: $data) {
      ...leanDeliveryFleet
    }
  }
`)

export const UPDATE_FLEET_LEAN = gql(`
  mutation UpdateFleetLean($filter: FleetFilterInput!, $data: UpdateFleetLeanInput!) {
    updateFleetLean(filter: $filter, data: $data) {
      ...leanDeliveryFleet
    }
  }
`)

export const SET_DELIVERY_ORDER = gql(`
  mutation SetDeliveryOrder($fleetId: String!, $sourceOrderId: String!, $deliveryOrder: Float!) {
    setDeliveryOrder(fleetId: $fleetId, sourceOrderId: $sourceOrderId, deliveryOrder: $deliveryOrder) {
      ...leanDeliveryFleet
    }
  }
`)

export const SORT_DELIVERY_ORDER = gql(`
  mutation SortDeliveryOrder($fleetId: String!, $sort: SortDirection!) {
    sortDeliveryOrder(fleetId: $fleetId, sort: $sort) {
      ...leanDeliveryFleet
    }
  }
`)

export const UPDATE_FLEET_LABEL = gql(`
  mutation UpdateFleetLabel($fleetId: String!, $label: String!) {
    updateFleetLabel(fleetId: $fleetId, label: $label)
  }
`)

export const UPDATE_FLEET_DESCRIPTION = gql(`
  mutation UpdateFleetDescription($fleetId: String!, $description: String!) {
    updateFleetDescription(fleetId: $fleetId, description: $description)
  }
`)

export const UPDATE_ZONE_AND_FLEET_TYPE = gql(`
  mutation UpdateZoneAndFleetType($fleetId: String!, $zones: [EmptyFleetZone!]!, $type: String) {
    updateZoneAndFleetType(fleetId: $fleetId, zones: $zones, type: $type) {
      ...leanDeliveryFleet
    }
  }
`)

export const MARK_AS_PACKAGE_READY = gql(`
  mutation MarkAsPackageReady($fleetId: String!, $input: [FleetPackageReadyInput!]!) {
    markAsPackageReady(fleetId: $fleetId, input: $input) {
      ...leanDeliveryFleet
    }
  }
`)

export const MARK_AS_BOARD = gql(`
  mutation MarkAsBoard($fleetId: String!, $input: [FleetPackageReadyInput!]!) {
    markAsBoard(fleetId: $fleetId, input: $input) {
      ...leanDeliveryFleet
    }
  }
`)

export const MARK_AS_PACKAGE_RETRIEVE = gql(`
  mutation MarkAsPackageRetrieve($fleetId: String!, $input: [FleetPackageReadyInput!]!) {
    markAsPackageRetrieve(fleetId: $fleetId, input: $input) {
      ...leanDeliveryFleet
    }
  }
`)

export const MARK_AS_SHIPPING = gql(`
  mutation MarkAsShipping($fleetId: String!) {
    markAsShipping(fleetId: $fleetId) {
      ...leanDeliveryFleet
    }
  }
`)

export const ADD_ORDER_IN_FLEET = gql(`
  mutation AddOrderInFleet($fleetId: String!, $sourceOrderId: String!) {
    addOrderInFleet(fleetId: $fleetId, sourceOrderId: $sourceOrderId) {
      ...leanDeliveryFleet
    }
  }
`)

export const REMOVE_ORDER_FROM_FLEET = gql(`
  mutation RemoveOrderFromFleet($fleetId: String!, $sourceOrderId: String!) {
    removeOrderFromFleet(fleetId: $fleetId, sourceOrderId: $sourceOrderId) {
      ...leanDeliveryFleet
    }
  }
`)

export const HIJACK_ORDERS = gql(`
  mutation HijackOrders($fleetId: String!, $items: [HijackItem!]!) {
    hijackOrders(fleetId: $fleetId, items: $items) {
      ...leanDeliveryFleet
    }
  }
`)

export const MOVE_ORDER_TO_OTHER_FLEET = gql(`
  mutation MoverOrderToOtherFleet($fleetId: String!, $sourceOrderId: String!, $sourceFleetId: String) {
    moveOrderToOtherFleet(fleetId: $fleetId, sourceOrderId: $sourceOrderId, sourceFleetId: $sourceFleetId) {
      ...leanDeliveryFleet
    }
  }
`)

export const CANCELLED_FLEET = gql(`
  mutation CancelledFleet($fleetId: String!) {
    cancelledFleet(fleetId: $fleetId) {
      ...leanDeliveryFleet
    }
  }
`)

export const CREATE_DELIVERY_FLEET_FROM_EXISTING_DELIVERY_FLEET = gql(`
  mutation CreateDeliveryFleetFromExistingDeliveryFleet($input: CreateDeliveryFleetFromExistingDeliveryFleetInput!) {
    createDeliveryFleetFromExistingDeliveryFleet(input: $input){
      ...leanDeliveryFleet 
    }
  }
  `)

export const COMBINE_DELIVERY_FLEET = gql(`
  mutation CombineDeliveryFleet($input: CombineDeliveryFleetInput!) {
    combineDeliveryFleet(input: $input)
  }
`)

export const ROLLBACK_DELIVERY_FLEET = gql(`
  mutation RollBackDeliveryFleet($fleetId: String!) {
    rollBackDeliveryFleet(fleetId: $fleetId)
  }
`)

/** ------------------------------------- FLEET MOBILE ------------------------------------- */

export const UPDATE_FLEET_MOBILE = gql(`
  mutation UpdateFleetMobile($data: FleetMobileUpdateInput!) {
    updateFleetMobile(data: $data) {
      ...leanDeliveryFleet
    }
  }
`)

export const VERIFIED_FLEET_EVIDENCE = gql(`
  mutation VerifiedFleetEvidence($fleetId: String!, $orderId: String!, $isRetrievable: Boolean) {
    verifiedFleetEvidence(fleetId: $fleetId, orderId: $orderId, isRetrievable: $isRetrievable)
  }
`)

/** ------------------------------------- OTHER UTIL(S) ------------------------------------- */
export const TOGGLE_IN_FLEET_ORDER_STATUS = gql(`
  mutation ToggleInFleetOrderStatus($sourceOrderId: String!) {
    toggleInFleetOrderStatus(sourceOrderId: $sourceOrderId)
  }
`)

export const COPY_GOOGLE_MAP_LINK = gql(`
  query CopyGoogleMapLink($links: [String!]!) {
    copyGoogleMapLink(links: $links)
  }
`)

export const TRANSFORM_GOOGLE_MAP_LINK_TO_COORDINATE_LINK = gql(`
  mutation transformShortenGoogleMapLinkToCoordinateLink($inputs: [DirectionInput!]!) {
    transformShortenGoogleMapLinkToCoordinateLink(inputs: $inputs) {
      directionUrl
      items {
        key
        mapLink
        valid
        message
        formattedLink
      }
    }
  }
`)
